/**
 * WARNING: This const is used for sorting, so the order
 * must remain as is.
 */
const POLL_STATUS = Object.freeze({
  CREATED: "created",
  OPENED: "opened",
  CLOSED: "closed",
  ARCHIVED: "archived",
});

const POLL_NEXT_STATUS = Object.freeze({
  CREATE: "create",
  OPEN: "open",
  CLOSE: "close",
  ARCHIVE: "archive",
});

export { POLL_STATUS, POLL_NEXT_STATUS };
