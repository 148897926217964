/**
 * @fileoverview Tracking events that the client knows about
 */
const TRACKING_EVENT_NAMES = {
  STAGE_AREA_ENTERED: "Stage Area Entered",
  GREENROOM_ENTERED: "Greenroom Entered",
  OVERLAY_CTA_CLICKED: "Overlay CTA Clicked",
  OVERLAY_CTA_SHOWN: "Overlay CTA Shown",
  RECORDING_CLIP_DOWNLOADED: "Recording Clip Downloaded",
  VIDEO_PLAYER_SHOW: "Video Player Show",
  VIDEO_PLAYBACK_START: "Video Playback Start",
  VIDEO_PLAYBACK_PAUSE: "Video Playback Pause",
  VIDEO_PLAYBACK_SEEK: "Video Playback Seek",
  VIDEO_PLAYBACK_KEEPALIVE: "Video Playback Keepalive",
  LOUNGE_CTA_CLICKED: "Lounge CTA Clicked",
  SHARE_EVENT_BUTTON_CLICK: "Share Event Button Click",
  TRACK_VISIT_WITH_UTM: "Track Visit with UTM",
  PRODUCER_POST_EVENT_FEEDBACK_SUBMISSION:
    "Producer Post Event Feedback Submission",
  ATTENDEE_POST_EVENT_FEEDBACK_SUBMISSION:
    "Attendee Post Event Feedback Submission",
  ATTENDEE_SHOWN_POST_EVENT_FEEDBACK: "Attendee Shown Post Event Feedback",
  ATTENDEE_POST_EVENT_FEEDBACK_THUMBS_UP:
    "Attendee Post Event Feedback Thumbs Up",
  ATTENDEE_POST_EVENT_FEEDBACK_THUMBS_DOWN:
    "Attendee Post Event Feedback Thumbs Down",
  ATTENDEE_POST_EVENT_FEEDBACK_DISMISS: "Attendee Post Event Feedback Dismiss",
  ATTENDEE_POST_EVENT_FEEDBACK_REVIEW: "Attendee Post Event Feedback Review",
  ATTENDEE_POST_EVENT_FEEDBACK_NO_THANKS:
    "Attendee Post Event Feedback No Thanks",
  ATTENDEE_POST_EVENT_FEEDBACK_CLOSE: "Attendee Post Event Feedback Close",
  TIMELINE_LEGACY_ASSET_PANEL_SELECTION:
    "Timeline Legacy Asset Panel Selection",
  AV_DEVICE_SELECTED: "A/V Device Selected",
  VIRTUAL_BG_FAILURE_SYSTEM_RESOURCES:
    "Virtual Background Failed Due To System Resources",
};
export default TRACKING_EVENT_NAMES;
