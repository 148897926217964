const MESSAGE_TYPE = {
  NEW: "NEW",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  UPVOTE: "UPVOTE",
  DOWNVOTE: "DOWNVOTE",
  ARCHIVE: "ARCHIVE",
  SET: "SET",
};

export default MESSAGE_TYPE;
