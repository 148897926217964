import TrackingEventNames from "~~/constants/trackingEventNames";
import store from "~~/redux/store";
import axios from "~~/utils/authenticatedAxios";

function sendRequestToValencia({ eventData }) {
  const { id: stageId, eventId } = store.getState().Stage;
  return axios.post(`/valencia`, {
    stageId,
    eventId,
    eventsToTrack: [eventData],
  });
}

export function trackLoungeCtaClicked({
  stageId,
  eventRegistrationId,
  loungeRoomId,
  loungeSessionId,
  ctaUrl,
}) {
  const properties = {
    stageId,
    eventRegistrationId,
    loungeRoomId,
    loungeSessionId,
    ctaUrl,
  };
  const name = TrackingEventNames.LOUNGE_CTA_CLICKED;

  sendRequestToValencia({ eventData: { name, properties } });
}

export function trackStageAreaEntered(stageArea) {
  const properties = {
    stageArea,
  };
  const name = TrackingEventNames.STAGE_AREA_ENTERED;

  sendRequestToValencia({ eventData: { name, properties } });
}

export function trackGreenRoomEntered(stageId, eventRegistrationId) {
  sendRequestToValencia({
    eventData: {
      name: TrackingEventNames.GREENROOM_ENTERED,
      properties: {
        stageId,
        eventRegistrationId,
      },
    },
  });
}

export function trackVideoPlayerShow({ stageRecordingFileId }) {
  sendRequestToValencia({
    eventData: {
      name: TrackingEventNames.VIDEO_PLAYER_SHOW,
      properties: {
        stageRecordingFileId,
      },
    },
  });
}

export function trackVideoPlaybackStart({ stageRecordingFileId }) {
  sendRequestToValencia({
    eventData: {
      name: TrackingEventNames.VIDEO_PLAYBACK_START,
      properties: {
        stageRecordingFileId,
      },
    },
  });
}

export function trackVideoPlaybackPause({
  stageRecordingFileId,
  videoTimestamp,
}) {
  sendRequestToValencia({
    eventData: {
      name: TrackingEventNames.VIDEO_PLAYBACK_PAUSE,
      properties: {
        stageRecordingFileId,
        videoTimestamp,
      },
    },
  });
}

export function trackVideoPlaybackSeek({
  stageRecordingFileId,
  videoTimestamp,
}) {
  sendRequestToValencia({
    eventData: {
      name: TrackingEventNames.VIDEO_PLAYBACK_SEEK,
      properties: {
        stageRecordingFileId,
        videoTimestamp,
      },
    },
  });
}

export function trackVideoPlaybackKeepalive({ stageRecordingFileId }) {
  sendRequestToValencia({
    eventData: {
      name: TrackingEventNames.VIDEO_PLAYBACK_KEEPALIVE,
      properties: {
        stageRecordingFileId,
      },
    },
  });
}

export async function trackProducerPostEventFeedbackSubmission({
  hadTechnicalIssues,
  issueCategories,
  issueDetails,
  userId,
  stageId,
  eventId,
  eventRegistrationId,
}) {
  return window.analytics?.track({
    user_id: userId,
    event: TrackingEventNames.PRODUCER_POST_EVENT_FEEDBACK_SUBMISSION,
    properties: {
      had_technical_issues: hadTechnicalIssues,
      issue_categories: issueCategories,
      issue_details: issueDetails,
      event_id: eventId,
      stage_id: stageId,
      event_registration_id: eventRegistrationId,
    },
  });
}

export async function trackAttendeePostEventFeedbackSubmission({
  issueDetails,
  userId,
  stageId,
  eventId,
  eventRegistrationId,
}) {
  return window.analytics?.track({
    user_id: userId,
    event: TrackingEventNames.ATTENDEE_POST_EVENT_FEEDBACK_SUBMISSION,
    properties: {
      issue_details: issueDetails,
      event_id: eventId,
      stage_id: stageId,
      event_registration_id: eventRegistrationId,
    },
  });
}

export async function trackAttendeeShownPostEventFeedback({
  userId,
  stageId,
  eventId,
  eventRegistrationId,
}) {
  return window.analytics?.track({
    user_id: userId,
    event: TrackingEventNames.ATTENDEE_SHOWN_POST_EVENT_FEEDBACK,
    properties: {
      event_id: eventId,
      stage_id: stageId,
      event_registration_id: eventRegistrationId,
    },
  });
}

export async function trackAttendeePostEventFeedbackThumbsUp({
  userId,
  stageId,
  eventId,
  eventRegistrationId,
}) {
  return window.analytics?.track({
    user_id: userId,
    event: TrackingEventNames.ATTENDEE_POST_EVENT_FEEDBACK_THUMBS_UP,
    properties: {
      event_id: eventId,
      stage_id: stageId,
      event_registration_id: eventRegistrationId,
    },
  });
}

export async function trackAttendeePostEventFeedbackThumbsDown({
  userId,
  stageId,
  eventId,
  eventRegistrationId,
}) {
  return window.analytics?.track({
    user_id: userId,
    event: TrackingEventNames.ATTENDEE_POST_EVENT_FEEDBACK_THUMBS_DOWN,
    properties: {
      event_id: eventId,
      stage_id: stageId,
      event_registration_id: eventRegistrationId,
    },
  });
}

export async function trackAttendeePostEventFeedbackDismiss({
  userId,
  stageId,
  eventId,
  eventRegistrationId,
}) {
  return window.analytics?.track({
    user_id: userId,
    event: TrackingEventNames.ATTENDEE_POST_EVENT_FEEDBACK_DISMISS,
    properties: {
      event_id: eventId,
      stage_id: stageId,
      event_registration_id: eventRegistrationId,
    },
  });
}

export async function trackAttendeePostEventFeedbackReview({
  userId,
  stageId,
  eventId,
  eventRegistrationId,
}) {
  return window.analytics?.track({
    user_id: userId,
    event: TrackingEventNames.ATTENDEE_POST_EVENT_FEEDBACK_REVIEW,
    properties: {
      event_id: eventId,
      stage_id: stageId,
      event_registration_id: eventRegistrationId,
    },
  });
}

export async function trackAttendeePostEventFeedbackNoThanks({
  userId,
  stageId,
  eventId,
  eventRegistrationId,
}) {
  return window.analytics?.track({
    user_id: userId,
    event: TrackingEventNames.ATTENDEE_POST_EVENT_FEEDBACK_NO_THANKS,
    properties: {
      event_id: eventId,
      stage_id: stageId,
      event_registration_id: eventRegistrationId,
    },
  });
}

export async function trackAttendeePostEventFeedbackClose({
  userId,
  stageId,
  eventId,
  eventRegistrationId,
}) {
  return window.analytics?.track({
    user_id: userId,
    event: TrackingEventNames.ATTENDEE_POST_EVENT_FEEDBACK_CLOSE,
    properties: {
      event_id: eventId,
      stage_id: stageId,
      event_registration_id: eventRegistrationId,
    },
  });
}

/**
 * @param {{
 *  isSharingOnDemand: boolean,
 *  shareEventUrl: string,
 *  shareEventTo: "linkedin" | "facebook" | "twitter" | "copylink",
 * }} params
 */
export function trackShareEventButtonClicked({
  isSharingOnDemand,
  shareEventUrl,
  shareEventTo,
}) {
  const {
    User: { currentUser },
    Event: event,
  } = store.getState();

  window.analytics?.track({
    user_id: currentUser.id,
    event: TrackingEventNames.SHARE_EVENT_BUTTON_CLICK,
    properties: {
      event_id: event.id,
      is_on_demand: isSharingOnDemand,
      share_to: shareEventTo,
      share_url: shareEventUrl,
    },
  });
}

export function trackCtaOverlayShown({ dynamicEventAssetId }) {
  const {
    User: { currentUser },
    Stage: { id: stageId },
  } = store.getState();

  const trackingParams = {
    user_id: currentUser.id,
    event: TrackingEventNames.OVERLAY_CTA_SHOWN,
    properties: {
      dynamic_event_asset_id: dynamicEventAssetId,
      stage_id: stageId,
    },
  };

  window.analytics?.track(trackingParams);
}

export function trackCtaOverlayClicked({ dynamicEventAssetId }) {
  const {
    Stage: { id: stageId },
  } = store.getState();

  return sendRequestToValencia({
    eventData: {
      name: TrackingEventNames.OVERLAY_CTA_CLICKED,
      properties: {
        stageId,
        dynamicEventAssetId: dynamicEventAssetId,
        // This function is only for the "signal" CTAs which do not
        // have links. "link" CTAs are handled on the backend.
        ctaDestinationUrl: "",
        rawCtaDestinationUrl: "",
      },
    },
  });
}

export function trackRecordingClipDownloaded({
  recordingClipId,
  isAutoGenerated,
}) {
  const {
    User: { currentUser },
  } = store.getState();

  const trackingParams = {
    user_id: currentUser.id,
    event: TrackingEventNames.RECORDING_CLIP_DOWNLOADED,
    properties: {
      recording_clip_id: recordingClipId,
      is_auto_generated: isAutoGenerated,
    },
  };

  window.analytics?.track(trackingParams);
}

export function trackAvDeviceSelected({ virtualBackgroundType }) {
  const {
    User: { currentUser },
    Stage: { id: stageId },
  } = store.getState();

  const trackingParams = {
    user_id: currentUser.id,
    event: TrackingEventNames.AV_DEVICE_SELECTED,
    properties: {
      virtual_background_type: virtualBackgroundType,
      stage_id: stageId,
    },
  };

  window.analytics?.track(trackingParams);
}

export function trackVirtualBackgroundFailed() {
  const {
    User: { currentUser },
    Stage: { id: stageId },
  } = store.getState();

  const trackingParams = {
    user_id: currentUser.id,
    event: TrackingEventNames.VIRTUAL_BG_FAILURE_SYSTEM_RESOURCES,
    properties: {
      stage_id: stageId,
    },
  };

  window.analytics?.track(trackingParams);
}
